<template>
  <v-list>
    <v-list-item prepend-icon="attach_file" :title="$t('attachments')" :subtitle="$t('fileCount', files?.length || 0)" class="pr-2">
      <template #append>
        <v-card-actions class="pa-0">
          <v-dialog width="600">
            <template #activator="{ props }">
              <v-btn v-bind="props" :disabled="!hasOrganizationWithScope(AuthScope.CAN_CREATE_FILES)" color="success">{{ $t('add') }}</v-btn>
            </template>

            <template #default="{ isActive }">
              <v-card :title="$t('addFiles')">
                <add-file-form
                  :create-function="(files: Array<File>) => createFunction ? createFunction(files) : fileStore.createFiles(files)"
                  @ok="(newFiles: Array<FileData>) => { emit('add', newFiles); isActive.value = false }"
                  @cancel="() => isActive.value = false"
                />
              </v-card>
            </template>
          </v-dialog>
        </v-card-actions>
      </template>
    </v-list-item>
  </v-list>

  <v-list v-if="files?.length && hasOrganizationWithScope(AuthScope.CAN_VIEW_FILES)">
    <file-item
      v-for="file of files"
      :key="file._id"
      :model-value="file"
      @delete="() => emit('delete', file._id)"
      @update:model-value="updateFile"
    />
  </v-list>

  <unauthorized-notification v-else-if="files?.length" :scope="AuthScope.CAN_VIEW_FILES" />
</template>

<script setup lang="ts">
import cloneDeep from "lodash-es/cloneDeep"
import { AuthScope } from "~~/stores/auth.store"

const fileStore = useFileStore()
const { hasOrganizationWithScope } = useAuthStore()

const emit = defineEmits<{
  (e: "add" | "update:files", files: Array<FileData>): void
  (e: "delete", fileId: string): void
}>()
const props = defineProps<{ files: Array<FileData>, createFunction?: (files: Array<File>) => Promise<Array<FileData>> }>()
const { files, createFunction } = toRefs(props)

const updateFile = (fileData: FileData) => {
  const fileIndex = files!.value.findIndex((f) => f._id === fileData._id)
  if (fileIndex === -1) return

  const updatedFileList = cloneDeep(files.value)
  updatedFileList[fileIndex] = fileData

  emit('update:files', updatedFileList)
}
</script>